import { Languages } from "@/customHooks/useTranslation/types.js";
import { ReactComponent as DutchFlag } from "../../../assets/dutchFlag.svg";
import { ReactComponent as EnglishFlag } from "../../../assets/englishFlag.svg";
import { ReactComponent as FrenchFlag } from "../../../assets/frenchFlag.svg";
import { ReactComponent as GermanFlag } from "../../../assets/germanFlag.svg";
import { ReactComponent as IndonesiaFlag } from "../../../assets/indonesiaFlag.svg";
import { ReactComponent as ItalyFlag } from "../../../assets/italyFlag.svg";
import { ReactComponent as PolandFlag } from "../../../assets/polandFlag.svg";
import { ReactComponent as PortugalFlag } from "../../../assets/portugalFlag.svg";
import { ReactComponent as RussianFlag } from "../../../assets/russianFlag.svg";
import { ReactComponent as SpainFlag } from "../../../assets/spainFlag.svg";

export const flagMatch: Record<Languages, JSX.Element> = {
    [Languages.EN]: <EnglishFlag />,
    [Languages.FR]: <FrenchFlag />,
    [Languages.ES]: <SpainFlag />,
    [Languages.RU]: <RussianFlag />,
    [Languages.DE]: <GermanFlag />,
    [Languages.PT]: <PortugalFlag />,
    [Languages.IT]: <ItalyFlag />,
    [Languages.NL]: <DutchFlag />,
    [Languages.PL]: <PolandFlag />,
    [Languages.ID]: <IndonesiaFlag />,
};

export const extendedLabel: Record<Languages, string> = {
    [Languages.EN]: "English",
    [Languages.FR]: "Français",
    [Languages.ES]: "Español",
    [Languages.RU]: "Русский",
    [Languages.DE]: "Deutsch",
    [Languages.PT]: "Português",
    [Languages.IT]: "Italiano",
    [Languages.NL]: "Nederlands",
    [Languages.PL]: "Polski",
    [Languages.ID]: "Indonesia",
};

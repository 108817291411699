import { Languages } from "@waapi/front-utils";
import { locale } from "dayjs";
import de from "dayjs/esm/locale/de.js";
import en from "dayjs/esm/locale/en-gb.js";
import es from "dayjs/esm/locale/es.js";
import fr from "dayjs/esm/locale/fr.js";
import id from "dayjs/esm/locale/id.js";
import it from "dayjs/esm/locale/it.js";
import nl from "dayjs/esm/locale/nl.js";
import pl from "dayjs/esm/locale/pl.js";
import pt from "dayjs/esm/locale/pt.js";
import ru from "dayjs/esm/locale/ru.js";

export const TRANSLATIONS_FRAGMENT = "front";
export const TRANSLATIONS_LOCAL_STORAGE_PREFIX = `i18next_res_${TRANSLATIONS_FRAGMENT}_palpatine`;

export const DAYJS_LOCALE_MAP: Record<Languages, Parameters<typeof locale>[0]> = {
    [Languages.EN]: en,
    [Languages.FR]: fr,
    [Languages.ES]: es,
    [Languages.DE]: de,
    [Languages.IT]: it,
    [Languages.NL]: nl,
    [Languages.PL]: pl,
    [Languages.PT]: pt,
    [Languages.RU]: ru,
    [Languages.ID]: id,
};
